<template>
  <tr v-if="rowData.displayYn">
    <td>
      <div v-if="rowData.infraAccountYn" class="box_choice">
        <RadioItem
          :id="'assetOpen' + index"
          name="assetOpen"
          :selectedId.sync="assetOpenIndexSync"
          @update:selectedId="$emit('onUpdateAssetOpen')"
        />
      </div>
    </td>
    <td>{{ index + 1 }}</td>
    <td>{{ rowData.accountName }}</td>
    <td>{{ rowData.taxName }}</td>
    <td>{{ rowData.deptName }}</td>
    <td>{{ rowData.serviceCode }}</td>
    <td>{{ partnerName }}</td>
    <td>{{ rowData.debit | currency }}</td>
    <td>{{ rowData.credit | currency }}</td>
  </tr>
</template>

<script>
import RadioItem from "@/_approval/components/common/radio/RadioItem";
export default {
  name: "StatementVoucherListLine",
  components: {
    RadioItem,
  },
  props: {
    rowData: Object,
    index: Number,
    partnerName: String,
    assetOpenIndex: Number, // 자산전표 활성화 index
  },
  computed: {
    assetOpenIndexSync: {
      get() {
        return "assetOpen" + this.assetOpenIndex;
      },
      set(value) {
        const selectedIdx = Number(value.replace("assetOpen", ""));
        this.$emit("update:assetOpenIndex", selectedIdx);
      },
    },
  },
};
</script>
